body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

#root {
	width: 100%;
}

.css-1i0rmet-RaLayout-root {
max-width: 100%;
min-width: none !important;

}

.leaflet-popup-content {
  margin: 50px 6px !important;
}

.leaflet-popup-content p {
	margin: 8px 0 !important;
	}

.leaflet-control-container .leaflet-routing-container-hide {
    display: none;
}	

.leaflet-routing-container.leaflet-bar.leaflet-control {
	display: none;
}

.fileUploader {
	width: 100%;
}

.fileContainer {
	background: #fff;
	box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.05);
	position: relative;
	border-radius: 10px;
	padding: 20px 0;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin: 10px auto;
	transition: all 0.3s ease-in;
}

.fileContainer input {
	opacity: 0;
	position: absolute;
	z-index: -1;
}

.fileContainer p {
	font-size: 12px;
	margin: 8px 0 4px;
}

.fileContainer .errorsContainer {
	max-width: 300px;
	font-size: 12px;
	color: red;
	text-align: left;
}

.fileContainer .chooseFileButton {
	padding: 6px 23px;
	background: #3f4257;
	border-radius: 30px;
	color: white;
	font-weight: 300;
	font-size: 14px;
	margin: 10px 0;
	transition: all 0.2s ease-in;
	cursor: pointer;
	outline: none;
	border: none;
}

.fileContainer .chooseFileButton:hover {
	background: #545972;
}

.fileContainer .uploadFilesButton {
	padding: 5px 43px;
	background: transparent;
	border-radius: 30px;
	color: #3f4257;
	font-weight: 300;
	font-size: 14px;
	margin: 10px 0;
	transition: all 0.2s ease-in;
	cursor: pointer;
	outline: none;
	border: 1px solid #3f4257;
}

.fileContainer .uploadFilesButton:hover {
	background: #3f4257;
	color: #fff;
}

.fileContainer .uploadIcon {
	width: 50px;
	height: 50px;
}

.fileContainer .uploadPicturesWrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	width: 100%;
}

.fileContainer .uploadPictureContainer {
	width: 25%;
	margin: 5%;
	padding: 10px;
	background: #edf2f6;
	display: flex;
	align-items: center;
	justify-content: center;
	height: inherit;
	box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.1);
	border: 1px solid #d0dbe4;
	position: relative;
}

.fileContainer .uploadPictureContainer img.uploadPicture {
	width: 100%;
}

.fileContainer .deleteImage {
	position: absolute;
	top: -9px;
	right: -9px;
	color: #fff;
	background: #ff4081;
	border-radius: 50%;
	text-align: center;
	cursor: pointer;
	font-size: 26px;
	font-weight: bold;
	line-height: 30px;
	width: 30px;
	height: 30px;

}

.flipMove {
	display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
}





.MuiPaper-root.MuiCard-root.RaCreate-card-235.MuiPaper-elevation1.MuiPaper-rounded {
	background-color : inherit;
	color: inherit;
	}

body > iframe[style*="2147483647"]:not([id="webpack-dev-server-client-overlay"]) {
		display: none;
	}

body > div.MuiDrawer-root.MuiDrawer-modal.RaSidebar-modal.MuiModal-root > div.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation16.MuiDrawer-paper.RaSidebar-paper.MuiDrawer-paperAnchorLeft.RaSidebar-paperAnchorLeft {
	width: 100% !important;
}

	/* App.css */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 300;
	src: url('../Roboto/Roboto-Light.ttf') format('truetype');
  }
  
  @font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	src: url('../Roboto/Roboto-Regular.ttf') format('truetype');
  }
  
  @font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	src: url('../Roboto/Roboto-Medium.ttf') format('truetype');
  }
  
  @font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	src: url('../Roboto/Roboto-Bold.ttf') format('truetype');
  }
  

